import React from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from './json/OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';
import { useCookies } from 'react-cookie';
import {envConfig} from './config'

const Signin = ({ config }) => {
  const [cookies, setCookie] = useCookies(['TARGETURI']);

  const { oktaAuth, authState } = useOktaAuth();
  if (!authState) return null;
  var str = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(str);
  console.log(params.toString())
  var redirectUrl = "";
  const fromURIValue = params.get('fromURI');
  const targetURIValue = params.get('TARGET');

  if (fromURIValue) {
    redirectUrl = fromURIValue;
  }
  if (targetURIValue) {
    redirectUrl = targetURIValue.substring(targetURIValue.indexOf("-SM-") + 4);
    redirectUrl = redirectUrl.replace(new RegExp("--", 'g'), '-');
  }

  const param =
    redirectUrl === ""
      ? "/redirect"
      : "/redirect?fromURI=" + encodeURIComponent(redirectUrl);
  console.log("original url: " + param);

  setCookie('TARGETURI', encodeURIComponent(redirectUrl) , { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });
  console.log('SignIn, cookie ' + JSON.stringify(cookies.TARGETURI))

  const onSuccess = (tokens) => {
    try {
      oktaAuth.handleLoginRedirect(tokens, param);
    } catch (err) {
      console.error("error logging in", err);
    }
  };

  const onError = (err) => {
    window.location.reload();
  };



  return authState.isAuthenticated ?
    <Redirect to={{pathname: param}}/> :
    <OktaSignInWidget
      config={config}
      onSuccess={onSuccess}
      onError={onError} /> ;
};

export default Signin;
