import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import classes from "../styles/signin.scss";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import Form from "./Form";
import logo from "../images/logo-main.png";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";

//test comment
export const getCustomState = (pathReceived, state) => {
    var customState = "";
    if (pathReceived.includes("/reactivate/emailed"))
        customState = "reactivate_emailed";
    else if (pathReceived.includes("/username/emailed"))
        customState = { path: "username_emailed", value: state };
    else if (pathReceived.includes("/recovery/emailed"))
        customState = "recovery_emailed";
    else if (pathReceived.includes("/unlock/emailed"))
        customState = "unlock_emailed";
    else if (pathReceived.includes("/reactivate"))
        customState = "reactivate";
    else if (pathReceived.includes("/forgot-username"))
        customState = { path: "username", value: state };
    else if (pathReceived.includes("/generic-error")) {
        customState = "generic_error";
    }
    return customState;

}
const Custom = () => {
    const { authState } = useOktaAuth();
    const location = useLocation();
    
 

    useEffect(() => {
        console.log(JSON.stringify(location));
    }, [location]);

    if (!authState) return null;
    return (
        <div className="login-wrapper login-wrapper-inner-page" id="login-wrapper">
            <div className="login-container">
                <div className="">
                    <div className="row w-100 m-0">
                        <div className="col-xl-6 p-0 col-lg-12 left-box">
                            <div className="phx-form-login m-auto">
                                <div >
                                    <div className='margin-center-widget'>
                                        <main
                                            className="auth-container main-container no-beacon no-beacond-heading-inner"
                                            style={{ classes }}
                                            data-se="auth-container"
                                            tabndex="-1"
                                            id="okta-sign-in"
                                        >
                                            <div className="okta-sign-in-header auth-header">
                                                <h1>
                                                    <img
                                                        src={logo}
                                                        className="auth-org-logo"
                                                        alt=" logo"
                                                        aria-label=" logo"
                                                    />
                                                </h1>
                                                <div data-type="beacon-container" className="beacon-container"></div>
                                            </div>
                                            <div className="auth-content">
                                                <div className="auth-content-inner">
                                                    <Form props={getCustomState(window.location.pathname, location.state)} />
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );


};
export default Custom;
